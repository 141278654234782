 .navigation{
   
 display: flex;
 justify-content: flex-end;
 background:dodgerblue;
 

} 
.nav{
  color: #fff
}
.navigation a{
    margin: 4px;
    padding: 3px;
    text-decoration: none;
    color: #fff;
    text-align: center;
}
@media (min-width:1070px){
    .navigation{
       background:dodgerblue ;
       justify-content: space-around;
    }
  } 
   
a{
  text-decoration: none;
  color: #fff;
}
   

 @media (max-width:400px){
    .navigation{
        flex-direction: column;
        background: black;
       
    }
    .navigation a{
        border: 1px solid rgb(205, 193, 218);
        margin: 4px; 
    }
    button{
      width: 100%;
    }
  } 
   
.navigation a:active{
  color: rgb(183, 218, 183);
}
.navigation a:hover{
  color: green;
}
.navigation a:link{
  color: rgb(66, 0, 128);
}
.App {
  text-align: center;
  background: linear-gradient(-10deg, rgb(32, 31, 31), rgb(245, 226, 245));
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

i{
  margin: 5px;
}
a:hover{
 color: darkgreen;
}